import { ICampaign } from "types/Dashboard"

export const translateFromBackend = (campaign: ICampaign) => {
    const values: any = { ...campaign }

    if (campaign.evaluation_type == 'ONE_TIME') {
        if (campaign.method == 'IMPORT') {
            values.target = 'CSV'
        } else if (campaign.method == 'ALL') {
            values.target = 'ALL'
        } else if (campaign.method == 'FOLLOW_UP') {
            values.target = 'FOLLOW_UP'
            values.mode = campaign.rule_params.mode
            values.wait_time = campaign.rule_params.wait_time
        } else {
            values.target = 'SEGMENT'
        }

        if (campaign.rule == 'IF_PAID_USING') {
            values.criteria = 'PAYMENT_METHOD'
            values.user = 'IF_PURCHASE'
            values.payment_method = campaign.rule_params.payment_method
            values.interval = campaign.rule_params.date_comparator
            values.date_a = campaign.rule_params.date_a
            values.date_b = campaign.rule_params.date_b
        } else if (campaign.rule == 'IF_PURCHASE_AT_LEAST') {
            values.criteria = 'ANY_PRODUCT'
            values.user = 'IF_PURCHASE'
            values.frequency = campaign.rule_params.frequency
            values.quantity_time = campaign.rule_params.quantity
            values.interval = campaign.rule_params.date_comparator
            values.date_a = campaign.rule_params.date_a
            values.date_b = campaign.rule_params.date_b
        } else if (campaign.rule == 'IF_PURCHASE_SKU') {
            values.user = 'IF_PURCHASE'
            values.criteria = 'PRODUCT'
            values.skus = campaign.rule_params.skus
            values.interval = campaign.rule_params.date_comparator
            values.date_a = campaign.rule_params.date_a
            values.date_b = campaign.rule_params.date_b
        } else if (campaign.rule == 'IF_AVG_SPENT_IS') {
            values.criteria = 'SPENT'
            values.spent_type = 'AVG'
            values.interval = campaign.rule_params.date_comparator
            values.date_a = campaign.rule_params.date_a
            values.date_b = campaign.rule_params.date_b
        } else if (campaign.rule == 'IF_PURCHASE_BEFORE_AT') {
            values.user = 'IF_PURCHASE'
            if (values.rule_params.skus === 'ALL_SKU') {
                values.criteria = 'ANY_PRODUCT'
            } else {
                values.criteria = 'PRODUCT'
                values.skus = campaign.rule_params.skus
            }
            if (campaign.rule_params.skus_category == 'ALL_SKU_BY_CATEGORY') {
                values.criteria = 'CATEGORY';
                values.category = campaign.rule_params.category;
            } else {
                if (!values.rule_params.comparator || values.rule_params.comparator == 'before') {
                    values.frequency = 'last_time'
                } else if (values.rule_params.comparator == 'after') {
                    values.frequency = 'last_time'
                } else if (values.rule_params.comparator == 'between') {
                    values.frequency = 'last_time'
                }
            }
            values.interval = campaign.rule_params.date_comparator

            values.date_a = values.rule_params.date
            values.date_b = values.rule_params.date_b
        } else if (campaign.rule == 'IF_TOTAL_SPENT_IS') {
            values.criteria = 'SPENT'
            values.spent_type = 'TOTAL'
            values.interval = campaign.rule_params.date_comparator
            values.date_a = campaign.rule_params.date_a
            values.date_b = campaign.rule_params.date_b
        }

        if (!values.frequency) {
            values.frequency = campaign.rule_params?.comparator
        }
    } else {
        if (campaign.rule == 'ON_SHIPPING_EVENT') {
            values.target = 'ORDER'
            values.change_type = 'SHIPPING_STATUS'
            values.shipping_status = campaign.rule_params.status
            values.interval = campaign.rule_params.date_comparator
            values.date_a = campaign.rule_params.date_a
            values.date_b = campaign.rule_params.date_b
            values.when = campaign.rule_params.unit
            if (values.when == 'days') {
                values.days = campaign.rule_params.time
            } else if (values.when == 'hours') {
                values.hours = campaign.rule_params.time
            } else if (values.when == 'weeks') {
                values.weeks = campaign.rule_params.time
            } else if (values.when == 'months') {
                values.months = campaign.rule_params.time
            } else if (values.when == 'years') {
                values.years = campaign.rule_params.time
            }
        } else if (campaign.rule == 'IF_PAID_USING') {
            values.criteria = 'PAYMENT_METHOD'
            values.target = 'USER'
            values.action = 'IF_PURCHASE'
            values.payment_method = campaign.rule_params.payment_method
            values.interval = campaign.rule_params.date_comparator
            values.when = campaign.rule_params.unit
            if (values.when == 'days') {
                values.days = campaign.rule_params.time
            } else if (values.when == 'hours') {
                values.hours = campaign.rule_params.time
            } else if (values.when == 'weeks') {
                values.weeks = campaign.rule_params.time
            } else if (values.when == 'months') {
                values.months = campaign.rule_params.time
            } else if (values.when == 'years') {
                values.years = campaign.rule_params.time
            }
        } else if (campaign.rule == 'IF_PURCHASE_BEFORE_AT') {
            values.criteria = 'ANY_PRODUCT'
            values.action = 'IF_PURCHASE'
            values.target = 'USER'
            values.frequency = 'LAST_TIME'
            if (campaign.rule_params.skus == 'ALL_SKU') {
                values.criteria = 'ANY_PRODUCT'
            } else {
                values.criteria = 'PRODUCT'
                values.skus = campaign.rule_params.skus
            }
            // if (campaign.rule_params.skus_category == 'ALL_SKU_BY_CATEGORY') {
            //     values.criteria = 'CATEGORY';
            //     values.category = campaign.rule_params.category;
            // }
            values.quantity_time = campaign.rule_params.quantity
            values.when = campaign.rule_params.unit

            if (values.when == 'days') {
                values.days = campaign.rule_params.time
            } else if (values.when == 'hours') {
                values.hours = campaign.rule_params.time
            } else if (values.when == 'weeks') {
                values.weeks = campaign.rule_params.time
            } else if (values.when == 'months') {
                values.months = campaign.rule_params.time
            } else if (values.when == 'years') {
                values.years = campaign.rule_params.time
            }
        } else if (campaign.rule == 'IF_PURCHASE_AT_LEAST') {
            values.criteria = 'ANY_PRODUCT'
            values.user = 'IF_PURCHASE'
            values.target = 'USER'
            values.frequency = campaign.rule_params.frequency
            values.quantity_time = campaign.rule_params.quantity
            values.when = campaign.rule_params.unit

            if (values.when == 'days') {
                values.days = campaign.rule_params.time
            } else if (values.when == 'hours') {
                values.hours = campaign.rule_params.time
            } else if (values.when == 'weeks') {
                values.weeks = campaign.rule_params.time
            } else if (values.when == 'months') {
                values.months = campaign.rule_params.time
            } else if (values.when == 'years') {
                values.years = campaign.rule_params.time
            }
        } else if (campaign.rule == 'IF_PURCHASE_COLLECTION') {
            values.action = 'IF_PURCHASE'
            values.criteria = 'CATEGORY'
            values.target = 'USER'
            values.product_categories = campaign.rule_params.product_categories
            values.when = campaign.rule_params.unit
            values.frequency = 'EACH_TIME'

            if (values.when == 'days') {
                values.days = campaign.rule_params.time
            } else if (values.when == 'hours') {
                values.hours = campaign.rule_params.time
            } else if (values.when == 'weeks') {
                values.weeks = campaign.rule_params.time
            } else if (values.when == 'months') {
                values.months = campaign.rule_params.time
            } else if (values.when == 'years') {
                values.years = campaign.rule_params.time
            }
        } else if (campaign.rule == 'IF_PURCHASE_SKU') {
            values.action = 'IF_PURCHASE'
            values.criteria = 'PRODUCT'
            values.target = 'USER'
            values.skus = campaign.rule_params.skus
            values.when = campaign.rule_params.unit
            values.frequency = 'EACH_TIME'

            if (campaign.rule_params.skus == 'ALL_SKU') {
                values.criteria = 'ANY_PRODUCT'
            } else {
                values.criteria = 'PRODUCT'
                values.skus = campaign.rule_params.skus
            }

            if (values.when == 'days') {
                values.days = campaign.rule_params.time
            } else if (values.when == 'hours') {
                values.hours = campaign.rule_params.time
            } else if (values.when == 'weeks') {
                values.weeks = campaign.rule_params.time
            } else if (values.when == 'months') {
                values.months = campaign.rule_params.time
            } else if (values.when == 'years') {
                values.years = campaign.rule_params.time
            }
        } else if (campaign.rule == 'IF_ABANDONED') {
            values.action = 'IF_ABANDONED'
            values.target = 'USER'
            values.when = campaign.rule_params.unit

            if (values.when == 'days') {
                values.days = campaign.rule_params.time
            } else if (values.when == 'hours') {
                values.hours = campaign.rule_params.time
            } else if (values.when == 'weeks') {
                values.weeks = campaign.rule_params.time
            } else if (values.when == 'months') {
                values.months = campaign.rule_params.time
            } else if (values.when == 'years') {
                values.years = campaign.rule_params.time
            }
        } else if (campaign.rule == 'IF_AVG_SPENT_IS') {
            values.criteria = 'SPENT'
            values.action = 'IF_PURCHASE'
            values.target = 'USER'
            values.spent_amount = campaign.rule_params.amount
            values.spent_type = 'AVG'
            values.when = campaign.rule_params.unit

            if (values.when == 'days') {
                values.days = campaign.rule_params.time
            } else if (values.when == 'hours') {
                values.hours = campaign.rule_params.time
            } else if (values.when == 'weeks') {
                values.weeks = campaign.rule_params.time
            } else if (values.when == 'months') {
                values.months = campaign.rule_params.time
            } else if (values.when == 'years') {
                values.years = campaign.rule_params.time
            }

            if (campaign.rule_params?.amount_comparator == 'at_least') {
                values.spent = 'AT_LEAST'
            } else if (campaign.rule_params.amount_comparator == 'less_than') {
                values.spent = 'LESS_OR_EQUAL'
            }
        } else if (campaign.rule == 'IF_TOTAL_SPENT_IS') {
            values.criteria = 'SPENT'
            values.action = 'IF_PURCHASE'
            values.spent_amount = campaign.rule_params.amount
            values.target = 'USER'
            values.spent_type = 'TOTAL'
            values.when = campaign.rule_params.unit

            if (values.when == 'days') {
                values.days = campaign.rule_params.time
            } else if (values.when == 'hours') {
                values.hours = campaign.rule_params.time
            } else if (values.when == 'weeks') {
                values.weeks = campaign.rule_params.time
            } else if (values.when == 'months') {
                values.months = campaign.rule_params.time
            } else if (values.when == 'years') {
                values.years = campaign.rule_params.time
            }

            if (campaign.rule_params.amount_comparator == 'at_least') {
                values.spent = 'AT_LEAST'
            } else if (campaign.rule_params.amount_comparator == 'less_than') {
                values.spent = 'LESS_OR_EQUAL'
            }
        }
    }

    return values
}

export const translateFromUI = (campaign: any) => {

    const values: any = { ...campaign, has: true }
    values.rule_params = values.rule_params || {}

    if (campaign.evaluation_type == 'ONE_TIME') {
        if (values.target == 'CSV') {
            values.method = 'IMPORT'
            values.rule = ''
        } else if (values.target == 'SEGMENT') {
            values.method = 'RULE'
        } else if (values.target == 'FOLLOW_UP') {
            values.method = 'FOLLOW_UP'
            values.rule_params.mode = campaign.mode
            values.rule_params.wait_time = campaign.wait_time
        } else {
            values.method = 'ALL'
            values.rule = ''
        }

        if (values.target == 'SEGMENT') {
            if (values.user == 'IF_PURCHASE') {
                if (values.criteria == 'PRODUCT') {
                    if (values.interval === 'last_time') {
                        values.rule = 'IF_PURCHASE_BEFORE_AT'
                        values.rule_params.date = values.date_a
                    } else {
                        values.rule = 'IF_PURCHASE_SKU'
                    }
                    values.rule_params.skus = values.skus
                } else if (values.criteria == 'ANY_PRODUCT') {
                    if (values.frequency == 'last_time') {
                        values.rule = 'IF_PURCHASE_BEFORE_AT'
                        values.rule_params.skus = 'ALL_SKU'
                        values.rule_params.date = values.date_a
                    } else {
                        values.rule = 'IF_PURCHASE_AT_LEAST'
                        values.rule_params.comparator = values.frequency
                        values.rule_params.quantity = values.quantity_time
                    }
                } else if (values.criteria == 'PAYMENT_METHOD') {
                    values.rule = 'IF_PAID_USING'
                    values.rule_params.payment_method = values.payment_method
                } else if (values.criteria == 'SPENT') {
                    if (values.spent_type == 'AVG') {
                        values.rule = 'IF_AVG_SPENT_IS'
                    } else if (values.spent_type == 'more_than') {
                        values.rule = 'IF_AVG_SPENT_IS'
                        values.rule_params.amount_comparator = values.spent_type;
                    } else if (values.spent_type == 'TOTAL') {
                        values.rule = 'IF_TOTAL_SPENT_IS'
                    }
                    values.rule_params.amount = values.amount
                } else if (values.criteria == 'CATEGORY') {
                    values.rule = 'IF_PURCHASE_COLLECTION'
                    values.rule_params.product_categories = values.product_categories
                }

                values.rule_params.date_comparator = values.interval
                values.rule_params.date_a = values.date_a
                values.rule_params.date_b = values.date_b
            }
        }
    } else {
        values.rule_params = {}
        values.method = 'RULE'

        if (values.target == 'ORDER') {
            if (values.change_type == 'SHIPPING_STATUS') {
                values.rule = 'ON_SHIPPING_EVENT'
                values.rule_params.status = values.shipping_status
            }
        } else if (values.target == 'USER') {
            if (values.action == 'IF_PURCHASE') {
                if (values.criteria == 'PAYMENT_METHOD') {
                    values.rule = 'IF_PAID_USING'
                    values.rule_params.payment_method = values.payment_method
                } else if (values.criteria == 'CATEGORY') {
                    values.rule = 'IF_PURCHASE_COLLECTION'
                    values.rule_params.product_categories = values.product_categories
                }
                else if (values.criteria == 'ANY_PRODUCT') {
                    if (values.frequency == 'EACH_TIME') {
                        values.rule = 'IF_PURCHASE_SKU'
                    } else {
                        values.rule = 'IF_PURCHASE_BEFORE_AT'
                    }
                    values.rule_params.skus = 'ALL_SKU'
                } else if (values.criteria == 'PRODUCT') {
                    if (values.frequency == 'EACH_TIME') {
                        values.rule = 'IF_PURCHASE_SKU'
                    } else {
                        values.rule = 'IF_PURCHASE_BEFORE_AT'
                    }
                    values.rule_params.skus = values.skus
                } else if (values.criteria == 'SPENT') {
                    values.rule = 'IF_TOTAL_SPENT_IS'
                    if (values.spent == 'AT_LEAST') {
                        values.rule_params.amount_comparator = 'at_least'
                    } else if (values.spent == 'LESS_OR_EQUAL') {
                        values.rule_params.amount_comparator = 'less_than'
                    }
                    values.rule_params.amount = values.spent_amount
                }
            } else if (values.action == 'IF_ABANDONED') {
                values.rule = 'IF_ABANDONED'
            }
        }
        values.rule_params.unit = values.when
        values.rule_params.time = values['days'] || values['hours'] || values['weeks'] || values['months'] || values['years'] || ''
        values.follow_up_campaign_id = values.follow_up_campaign_id ? parseInt(values.follow_up_campaign_id) : null
    }

    return values
}