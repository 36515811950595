import React, { useEffect, useRef, useState } from 'react';

//import Components
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, InputGroup, Label, Row, Spinner, UncontrolledAlert } from 'reactstrap';

import { Link, useNavigate } from 'react-router-dom';

//Import Flatepicker
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es"
import { Field, FieldArray, FormikProvider, useFormik } from 'formik';
import CampaignCondition from './CampaignCondition';
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import { getPaymentMethods, getProductCategories } from 'slices/stores/thunk';
import { useSelector } from 'react-redux';
import { selectCampaigns, selectGroup, selectProfile, selectTemplate } from 'selectors';
import { getTemplates } from 'slices/templates/thunk';
import { getCampaigns } from 'slices/campaign/thunk';
import { translateFromBackend, translateFromUI } from './campaignTransformer';
import { getGroups } from 'slices/groups/thunk';
import TimezoneDropdown from 'Components/Common/TimezoneDropdown';
import moment from 'moment-timezone';
import TemplatePreviewModal from 'Components/Templates/TemplatePreviewModal';
import TemplateDropdown from 'Components/Templates/TemplateDropdown';


const CampaignForm = ({
    onSubmit,
    campaign,
}: any) => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const { currentStoreId } = useSelector(selectProfile)
    const { campaignItems, status } = useSelector(selectCampaigns)
    const { templateItems } = useSelector(selectTemplate)
    const { groupItems } = useSelector(selectGroup)
    const translatedCampaign: any = campaign ? translateFromBackend(campaign) : null
    const dateRef = useRef<any>(null)


    useEffect(() => {
        if (currentStoreId) {
            dispatch(
                getGroups({
                    storeId: currentStoreId
                })
            )

            dispatch(
                getPaymentMethods({
                    storeId: currentStoreId,
                })
            )

            dispatch(
                getCampaigns({
                    storeId: currentStoreId,
                    all: true,
                    system_only: false,
                })
            )

            dispatch(
                getTemplates({
                    storeId: currentStoreId,
                    all: true,
                })
            )

            dispatch(
                getProductCategories({
                    storeId: currentStoreId,
                })
            )
        }
    }, [currentStoreId])

    const formik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            file: '',
            timezone: translatedCampaign?.timezone ?? '',
            evaluation_type: translatedCampaign?.evaluation_type ?? '',
            campaign_type: translatedCampaign?.campaign_type ?? '',
            name: translatedCampaign?.name ?? '',
            scheduled_at: translatedCampaign?.scheduled_at ?? '',
            target: translatedCampaign?.target ?? '',
            action: translatedCampaign?.action ?? '',
            when: translatedCampaign?.when ?? '',
            message_template_id: translatedCampaign?.message_template_id ?? '',
            enabled_message_templates_ids: translatedCampaign?.enabled_message_templates_ids ?? [] as number[],
            follow_up_campaign_id: translatedCampaign?.follow_up_campaign_id ?? '',
            criteria: translatedCampaign?.criteria ?? '',
            spent_type: translatedCampaign?.spent_type ?? '',
            interval: translatedCampaign?.interval ?? '',
            date_a: translatedCampaign?.date_a ?? '',
            campaign_group_id: translatedCampaign?.campaign_group_id ?? '',
            date_b: translatedCampaign?.date_b ?? '',
            hours: translatedCampaign?.hours ?? '',
            months: translatedCampaign?.months ?? '',
            years: translatedCampaign?.years ?? '',
            days: translatedCampaign?.days ?? '',
            change_type: translatedCampaign?.change_type ?? '',
            shipping_event: translatedCampaign?.shipping_event ?? '',
            payment_method: translatedCampaign?.payment_method ?? '',
            skus: translatedCampaign?.skus ?? '',
            spent: translatedCampaign?.spent ?? '',
            spent_amount: translatedCampaign?.spent_amount ?? '',
            frequency: translatedCampaign?.frequency ?? '',
            user: translatedCampaign?.user ?? '',
            quantity_time: translatedCampaign?.quantity_time ?? '',
            coupon: translatedCampaign?.coupon ?? '',
            shipping_status: translatedCampaign?.shipping_status ?? '',
            mode: translatedCampaign?.mode ?? '',
            wait_time: translatedCampaign?.wait_time ?? '',
            product_categories: translatedCampaign?.product_categories ?? '',
            spending_limit: translatedCampaign?.spending_limit ?? '',
        },
        onSubmit: values => {
            onSubmit(translateFromUI(values))
        },
    });


    const _clearDate = () => {
        if (dateRef.current) {
            dateRef.current.flatpickr.clear();
            formik.setFieldValue("scheduled_at", null)
            formik.setFieldValue("timezone", null)
        }
    }
    return (
        <React.Fragment>
            <Container fluid>
                {formik.values.target == 'FOLLOW_UP' && <UncontrolledAlert color="secondary" className="alert-success alert-dismissible bg-success text-white alert-label-icon fade show">
                    <i className="ri-chat-4-line label-icon"></i><strong>Campaña de seguimiento</strong> - Los envíos de seguimiento que tengan menos de 22 horas desde el primer mensaje, tendrán un costo reducido de 0.4 créditos! <i className='mdi mdi-party-popper'></i> <i className='mdi mdi-party-popper'></i> <i className='mdi mdi-party-popper'></i>
                </UncontrolledAlert>}
                {translatedCampaign && <Row>
                    <Col xxl={(formik.values.evaluation_type == 'ONE_TIME' && formik.values.target !== 'FOLLOW_UP') ? 9 : 12}>
                        <Card >
                            <CardBody>
                                <p className="text-muted">Desde aquí podrás crear una campaña para enviar notificaciones usando diferentes reglas o bien, importar un archivo con los contactos.</p>
                                <div className="live-preview">
                                    <Form onSubmit={(e: any) => {
                                        e.preventDefault();
                                        formik.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="evaluation_type" className="form-label font-bold">Tipo de campaña</Label>
                                                    <Input
                                                        type="select"
                                                        name="evaluation_type"
                                                        className="form-control"
                                                        value={formik.values.evaluation_type}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.evaluation_type && formik.touched.evaluation_type)}
                                                        required
                                                    >
                                                        <option value="">-- Seleccione el tipo de campaña --</option>
                                                        <option value="ONE_TIME">Única vez</option>
                                                        <option value="ALL_TIME">Siempre activa</option>
                                                    </Input>
                                                    {formik.errors.evaluation_type && formik.touched.evaluation_type ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.evaluation_type}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="target" className="form-label">Destinatario</Label>
                                                    <Input
                                                        type="select"
                                                        name="target"
                                                        className="form-control"
                                                        value={formik.values.target}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.target && formik.touched.target)}
                                                        required
                                                    >
                                                        <option value="">-- Seleccione una opción --</option>
                                                        {formik.values.evaluation_type == 'ALL_TIME' && <option value="ORDER">Actualizaciones de orden</option>}
                                                        {formik.values.evaluation_type == 'ALL_TIME' && <option value="USER">Comportamiento del usuario</option>}
                                                        {formik.values.evaluation_type == 'ONE_TIME' && <option value="FOLLOW_UP">Campaña de seguimiento</option>}
                                                        {formik.values.evaluation_type == 'ONE_TIME' && <option value="SEGMENT">Crear un segmento</option>}
                                                        {formik.values.evaluation_type == 'ONE_TIME' && <option value="CSV">Subir un archivo .CSV</option>}
                                                        {formik.values.evaluation_type == 'ONE_TIME' && <option value="ALL">Toda la base</option>}
                                                    </Input>
                                                    {formik.errors.target && formik.touched.target ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.target}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            {formik.values.target == 'CSV' && <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="target" className="form-label">Importar desde .CSV</Label>
                                                    <Input
                                                        type="file"
                                                        name="file"
                                                        className="form-control"
                                                        onBlur={formik.handleBlur}
                                                        onChange={(event: any) => {
                                                            console.log(event)
                                                            formik.setFieldValue("file", event.target.files[0]);
                                                        }}
                                                        invalid={!!(formik.errors.file && formik.touched.file)}
                                                    />
                                                    {formik.errors.file && formik.touched.file ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.file}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>}
                                            <Col md={12}>
                                                <Row>
                                                    <CampaignCondition formik={formik} evaluation_type={formik.values.evaluation_type} target={formik.values.target} />
                                                </Row>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="campaign_type" className="form-label">Objetivo de campaña</Label>
                                                    <Input
                                                        type="select"
                                                        name="campaign_type"
                                                        required
                                                        className="form-control"
                                                        value={formik.values.campaign_type}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.campaign_type && formik.touched.campaign_type)}
                                                    >
                                                        <option value="">-- Seleccione el objetivo de la campaña --</option>
                                                        <option value="CONVERSION">Conversión</option>
                                                        <option value="TRANSACTIONAL">Transaccional</option>
                                                    </Input>
                                                    {formik.errors.campaign_type && formik.touched.campaign_type ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.campaign_type}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="message_template_id" className="form-label">Plantilla de mensaje</Label>
                                                    <TemplateDropdown
                                                        type="select"
                                                        required
                                                        name="message_template_id"
                                                        className="form-control"
                                                        value={formik.values.message_template_id}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.message_template_id && formik.touched.message_template_id)}
                                                        selectLabel="-- Seleccione la plantilla de mensaje que enviará esta campaña --"
                                                        showPreviewButton
                                                    />
                                                    {formik.errors.message_template_id && formik.touched.message_template_id ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.message_template_id}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <FormikProvider value={formik}>
                                                <FieldArray
                                                    name="enabled_message_templates_ids"
                                                    render={({ insert, remove, push, }) => (
                                                        <div>
                                                            {formik.values.enabled_message_templates_ids && formik.values.enabled_message_templates_ids.map((friend: any, index: any) => (
                                                                <div key={index}>
                                                                    <Row>
                                                                        <Col sm={12}>
                                                                            <Label>Plantilla de backup</Label>
                                                                            <Input
                                                                                type="select"
                                                                                required
                                                                                name={`enabled_message_templates_ids.${index}`}
                                                                                className="form-control"
                                                                                value={formik.values.enabled_message_templates_ids[index]}
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={formik.handleChange}
                                                                                invalid={!!(formik.errors.enabled_message_templates_ids && formik.errors.enabled_message_templates_ids[index] && formik.touched.enabled_message_templates_ids[index])}
                                                                            >
                                                                                <option value="">-- Seleccione la plantilla de mensaje de backup para esta campaña --</option>
                                                                                {templateItems.map((each: any) => <option value={each.id} key={each.id}>{each.name}</option>)}
                                                                            </Input>
                                                                            {formik.errors.enabled_message_templates_ids && formik.errors.enabled_message_templates_ids[index] && formik.touched.enabled_message_templates_ids[index] ? (
                                                                                <FormFeedback type="invalid" className='d-block'>{formik.errors.enabled_message_templates_ids[index]}</FormFeedback>
                                                                            ) : null}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col sm={6}>
                                                                            <button
                                                                                type="button"
                                                                                className='btn btn-sm btn-danger mt-2 mb-4'
                                                                                onClick={() => remove(index)}>
                                                                                <i className='mdi mdi-delete'></i> Eliminar
                                                                            </button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )
                                                            )}
                                                            <button type="button" className="btn btn-sm btn-success mb-3" onClick={() => push('')}>
                                                                <i className="mdi mdi-plus"></i> Agregar plantilla de backup
                                                            </button>
                                                        </div>)}
                                                />
                                            </FormikProvider>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="coupon" className="form-label">Cupón de descuento</Label>
                                                    <Input
                                                        type="text"
                                                        name="coupon"
                                                        className="form-control"
                                                        value={formik.values.coupon}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.coupon && formik.touched.coupon)}
                                                    />
                                                    {formik.errors.coupon && formik.touched.coupon ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.coupon}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="campaign_group_id" className="form-label">Enlazar esta campaña a un conjunto de campañas existente <span className="text-muted">(opcional)</span></Label>
                                                    <Input
                                                        type="select"
                                                        name="campaign_group_id"
                                                        className="form-control"
                                                        value={formik.values.campaign_group_id}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.campaign_group_id && formik.touched.campaign_group_id)}
                                                    >
                                                        <option value="">-- Seleccione el conjunto de campañas --</option>
                                                        {(groupItems || []).map((each: any) => <option value={each.id}>{each.name}</option>)}
                                                    </Input>
                                                    {formik.errors.campaign_group_id && formik.touched.campaign_group_id ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.campaign_group_id}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="follow_up_campaign_id" className="form-label">Enviar solo si el usuario recibió cierta campaña anterior {formik.values.target !== 'FOLLOW_UP' && <span className="text-muted">(opcional)</span>}</Label>
                                                    <Input
                                                        type="select"
                                                        name="follow_up_campaign_id"
                                                        className="form-control"
                                                        value={formik.values.follow_up_campaign_id}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.follow_up_campaign_id && formik.touched.follow_up_campaign_id)}
                                                        required={formik.values.target === 'FOLLOW_UP'}
                                                    >
                                                        <option value="">-- Seleccione la campaña que el usuario debió recibir primero antes de recibir la actual --</option>
                                                        {(campaignItems || []).map((each: any) => (each.evaluation_type != 'ONE_TIME' || formik.values.target != 'FOLLOW_UP') && <option value={each.id} key={each.id}>{each.name}</option>)}
                                                    </Input>
                                                    {formik.errors.follow_up_campaign_id && formik.touched.follow_up_campaign_id ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.follow_up_campaign_id}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="name" className="form-label">Nombre de campaña</Label>
                                                    <Input
                                                        required
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        value={formik.values.name}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.name && formik.touched.name)}
                                                    />
                                                    {formik.errors.name && formik.touched.name ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            {/* <Col sm={12} md={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="spending_limit" className="form-label">Limite de precio de campaña</Label>
                                                    <Input
                                                        type="number"
                                                        name="spending_limit"
                                                        className="form-control"
                                                        value={formik.values.spending_limit}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        invalid={!!(formik.errors.spending_limit && formik.touched.spending_limit)}
                                                    />
                                                    {formik.errors.spending_limit && formik.touched.spending_limit ? (
                                                        <FormFeedback type="invalid" className='d-block'>{formik.errors.spending_limit}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            {formik.values.evaluation_type === 'ALL_TIME' && (
                                                <Col sm={12} md={12}>
                                                    <div>
                                                        <label htmlFor="spending_period">Frecuencia</label>
                                                        <Input type="select"
                                                            name="spending_period"
                                                            className="form-control"
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            required={formik.values.target === 'ALL_TIME'}
                                                        >
                                                            <option value="">Seleccione una opción</option>
                                                            <option value="DAILY">Diaria</option>
                                                            <option value="WEEKLY">Semanal</option>
                                                            <option value="MONTHLY">Mensual</option>
                                                        </Input>
                                                    </div>
                                                </Col>
                                            )} */}
                                            <Col md={12}>
                                                <div className="text-end">
                                                    <button type="button" className="btn btn-light mr-2" onClick={() => navigate('/campaigns/')}>Cancelar</button>{' '}
                                                    <button type="submit" className="btn btn-primary" disabled={status === 'loading'}>
                                                        {status === 'loading' && <span className="d-flex align-items-center">
                                                            <span className="flex-grow-1 me-2">
                                                                Cargando...
                                                            </span>
                                                            <Spinner size="sm" className="flex-shrink-0" role="status"> Cargando... </Spinner>
                                                        </span>}{' '}
                                                        {status !== 'loading' && formik.values.action !== 'IF_ABANDONED' && 'Presupuestar'}
                                                        {status !== 'loading' && formik.values.action === 'IF_ABANDONED' && 'Guardar'}
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    {(formik.values.evaluation_type == 'ONE_TIME' && formik.values.target !== 'FOLLOW_UP') && <Col xs={3}>
                        <Card>
                            <CardBody>
                                <p>Programar la fecha de activación</p>
                                <Row>
                                    <Col xs={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="scheduled_at" className="form-label font-bold">Activar automáticamente el</Label>
                                            <div className="input-group">
                                                <Button color='danger' onClick={() => _clearDate()}>
                                                    <i className="mdi mdi-close"></i>
                                                </Button>
                                                <Flatpickr
                                                    ref={dateRef}
                                                    className="form-control"
                                                    value={formik.values.scheduled_at}
                                                    options={{
                                                        locale: Spanish,
                                                        enableTime: true,
                                                    }}
                                                    onChange={(selectedDates: any, dateStr: any, instance: any) => {
                                                        let value;

                                                        if (selectedDates) {
                                                            value = moment(selectedDates[0]).format('YYYY-MM-DDTHH:mm:ss')
                                                        } else {
                                                            value = null
                                                        }

                                                        formik.setFieldValue("scheduled_at", value)
                                                    }}
                                                />
                                            </div>
                                            {formik.errors.scheduled_at && formik.touched.scheduled_at ? (
                                                <FormFeedback type="invalid" className='d-block'>{formik.errors.scheduled_at}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="timezone" className="form-label font-bold">Zona horaria</Label>
                                            <TimezoneDropdown
                                                placeholder='Zona horaria de campaña'
                                                required={!!formik.values.scheduled_at}
                                                value={formik.values.timezone ? {
                                                    value: formik.values.timezone,
                                                    label: formik.values.timezone,
                                                } : null}
                                                onChange={(item: any) => {
                                                    formik.setFieldValue("timezone", item.value)
                                                }}
                                            />
                                            {formik.errors.timezon && formik.touched.timezone ? (
                                                <FormFeedback type="invalid" className='d-block'>{formik.errors.timezone}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>}
                </Row>}

            </Container>
        </React.Fragment >
    );
};

export default CampaignForm;
